<template>
    <div>
        <!-- Pricing toggle -->
        <div class="flex justify-center max-w-[14rem] m-auto mb-8 lg:mb-16">
            <div class="relative flex w-full p-1 bg-slate-200 dark:bg-slate-900 rounded-full">
                <span class="absolute inset-0 m-1 pointer-events-none" aria-hidden="true">
                    <span
                        class="absolute inset-0 w-1/2 bg-blue-600 rounded-full shadow-sm shadow-indigo-950/10 transform transition-transform duration-150 ease-in-out"
                        :class="!isAnnual ? 'translate-x-0' : 'translate-x-full'"></span>
                </span>

                <button
                    class="relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out"
                    :class="isAnnual ? 'text-slate-500 dark:text-slate-400' : 'text-white'" @click="isAnnual = false"
                    :aria-pressed="isAnnual">{{ $t('monthly') }}</button>
                <button
                    class="relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out"
                    :class="isAnnual ? 'text-white' : 'text-slate-500 dark:text-slate-400'" @click="isAnnual = true"
                    :aria-pressed="isAnnual">{{ $t('yearly') }} <span
                        :class="isAnnual ? 'text-indigo-200' : 'text-slate-400 dark:text-slate-500'">-20%</span></button>
            </div>
        </div>

        <div class="max-w-sm mx-auto grid gap-6 lg:grid-cols-4 items-start lg:max-w-none">

            <PricingTab :yearly="isAnnual" :popular="false" planName="Free" :price="{ yearly: 0, monthly: 0 }"
                :planDescription="$t('freeDescription')" :includedFeatures="includedFeatures['Free']"
                :extensionFeatures="extensionFeatures['Free']" @subscribe="subscribe('Free', $event)"
                :isLoading="isLoading" />

            <PricingTab :yearly="isAnnual" :popular="false" planName="Base" :price="{ yearly: 40, monthly: 48 }"
                :planDescription="$t('baseDescription')" :includedFeatures="includedFeatures['Base']"
                :extensionFeatures="extensionFeatures['Base']" @subscribe="subscribe('Base', $event)"
                @switchPlan="switchPlan('Base', $event)" :isLoading="isLoading" />

            <PricingTab :yearly="isAnnual" :popular="true" planName="Pro" :price="{ yearly: 105, monthly: 126 }"
                :planDescription="$t('proDescription')" :includedFeatures="includedFeatures['Pro']"
                :extensionFeatures="extensionFeatures['Pro']" @subscribe="subscribe('Pro', $event)"
                @switchPlan="switchPlan('Pro', $event)" :isLoading="isLoading" />

            <PricingTab :yearly="isAnnual" :popular="false" planName="Enterprise" :price="{ yearly: 240, monthly: 288 }"
                :planDescription="$t('enterpriseDescription')" :includedFeatures="includedFeatures['Enterprise']"
                :extensionFeatures="extensionFeatures['Enterprise']" @subscribe="subscribe('Enterprise', $event)"
                @switchPlan="switchPlan('Enterprise', $event)" :isLoading="isLoading" />
        </div>
        <div class="text-sm text-slate-500 mt-2">{{ $t('vat') }}</div>
    </div>
</template>

<script>
import PricingTab from './PricingTab.vue'

export default {
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PricingTab
    },
    computed: {
        includedFeatures() {
            return {
                "Free": [
                    { isActive: true, text: this.$t('includedFeature1') },
                    { isActive: false, text: this.$t('includedFeature2a') + this.$t('freeContingent') + this.$t('includedFeature2b') },
                    { isActive: true, text: this.$t('includedFeature3') },
                    { isActive: true, text: this.$t('includedFeature4') },
                    { isActive: true, text: this.$t('includedFeature5') },
                    { isActive: true, text: this.$t('includedFeature6') },
                    { isActive: false, text: this.$t('includedFeature7') },
                    { isActive: false, text: this.$t('includedFeature8') },
                    // { isActive: false, text: "Nutzer können Sprachen anfordern <br> (comming soon)" },
                ],
                "Base": [
                    { isActive: true, text: this.$t('includedFeature1') },
                    { isActive: true, text: this.$t('includedFeature2a') + this.$t('baseContingent') + this.$t('includedFeature2b') },
                    { isActive: true, text: this.$t('includedFeature3') },
                    { isActive: true, text: this.$t('includedFeature4') },
                    { isActive: true, text: this.$t('includedFeature5') },
                    { isActive: true, text: this.$t('includedFeature6') },
                    { isActive: true, text: this.$t('includedFeature7') },
                    { isActive: true, text: this.$t('includedFeature8') },
                    // { isActive: false, text: "Nutzer können Sprachen anfordern <br> (comming soon)" },
                ],
                "Pro": [
                    { isActive: true, text: this.$t('includedFeature1') },
                    { isActive: true, text: this.$t('includedFeature2a') + this.$t('proContingent') + this.$t('includedFeature2b') },
                    { isActive: true, text: this.$t('includedFeature3') },
                    { isActive: true, text: this.$t('includedFeature4') },
                    { isActive: true, text: this.$t('includedFeature5') },
                    { isActive: true, text: this.$t('includedFeature6') },
                    { isActive: true, text: this.$t('includedFeature7') },
                    { isActive: true, text: this.$t('includedFeature8') },
                    // { isActive: true, text: "Nutzer können Sprachen anfordern <br> (comming soon)" },
                ],
                "Enterprise": [
                    { isActive: true, text: this.$t('includedFeature1') },
                    { isActive: true, text: this.$t('includedFeature2a') + this.$t('enterpriseContingent') + this.$t('includedFeature2b') },
                    { isActive: true, text: this.$t('includedFeature3') },
                    { isActive: true, text: this.$t('includedFeature4') },
                    { isActive: true, text: this.$t('includedFeature5') },
                    { isActive: true, text: this.$t('includedFeature6') },
                    { isActive: true, text: this.$t('includedFeature7') },
                    { isActive: true, text: this.$t('includedFeature8') },
                    // { isActive: true, text: "Nutzer können Sprachen anfordern <br> (comming soon)" },
                ],
            }
        },
        extensionFeatures() {
            return {
                "Free": [
                    this.$t('extendedFeature1') + "15€",
                    this.$t('extendedFeature2a') + "2,5€" + this.$t('extendedFeature2b'),
                ],
                "Base": [
                    this.$t('extendedFeature1') + "8€",
                    this.$t('extendedFeature2a') + "2€" + this.$t('extendedFeature2b'),
                ],
                "Pro": [
                    this.$t('extendedFeature1') + "7€",
                    this.$t('extendedFeature2a') + "1,5€" + this.$t('extendedFeature2b'),
                ],
                "Enterprise": [
                    this.$t('extendedFeature1') + "6€",
                    this.$t('extendedFeature2a') + "1€" + this.$t('extendedFeature2b'),
                ],

            }
        }
    },
    data() {
        return {
            isAnnual: true,
        }
    },
    methods: {
        subscribe(plan, isAnnual) {
            this.$emit('subscribe', plan, isAnnual)
        },
        switchPlan(plan, isAnnual) {
            this.$emit('switchPlan', plan, isAnnual)
        }
    }
}
</script>
